import React from 'react';

import { about_us_design_rush_logo, about_us_design_rush_logo_container, linktext1 } from './LinkText1.module.scss';

const LinkText1: React.FC = () => {
    return (
        <div className="d-flex flex-direction-row p-2" data-testid="linktext1-container">
            <div className="row">
                <div className={`col-lg-5 col-md-4 ${about_us_design_rush_logo_container}`}>
                    <a href="https://www.designrush.com/agency/it-services">
                        <img
                            className={`${about_us_design_rush_logo} img-fluid ${linktext1}`}
                            data-testid="linktext1-image"
                            src="../../images/design-rush.png"
                            alt="Picture of Design Rush's Logo"
                        />
                    </a>
                </div>
                <div className="benefits-list col-lg-5 col-md-8 p-5" data-testid="linktext1-list">
                    <h2>Our Company</h2>
                    <p>
                        We are an IT company located in the beautiful state of Utah, and our passion is providing the
                        best IT services. We know that every company needs an IT solution that can provide Cloud,
                        Development, or Managed Services. But not only that, you need fast, reliable help. We aim for a
                        more personal experience. We are not just a company helping another company, but People helping
                        people. All we need is a list of your problems, then we will provide you solutions because we
                        simply Get IT Done.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LinkText1;
