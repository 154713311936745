import React from 'react';
import { linktext2 } from './LinkText2.module.scss';

const LinkText2: React.FC = () => {
    return (
        <div className="d-flex flex-direction-row p-2" data-testid="linktext2-container">
            <div className="row">
                <div className="benefits-list col-lg-5 col-md-7 p-4" data-testid="linktext2-list">
                    <h2>Our People</h2>
                    <p>
                        We have a team of experts who can do it all. We have cloud experts that work with a variety of
                        providers that not only ensure your online presence is up and running but also make sure your
                        environment is continually improving. We have developers that can turn your ideas into a ready
                        to use app or website.
                    </p>
                </div>
                <div className="col-lg-7 col-md-5">
                    <img
                        src="../images/our-people-image.jpg"
                        data-testid="linktext2-image"
                        className={`${linktext2} img-fluid`}
                        alt="Group of Developers sitting on stairs"
                    />
                </div>
            </div>
        </div>
    );
};

export default LinkText2;
