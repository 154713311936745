import React from 'react';
import { about_us_header_image } from './AboutUsHeader.module.scss';

const AboutUsHeader: React.FC = () => {
    return (
        <div className="about-us-header-container " data-testid="about-us-header-container">
            <div className="d-flex flex-direction-row" data-testid="about-us-header-technology">
                <div className="about-us-text col-5 m-1 d-flex align-items-center justify-content-center">
                    <h1>We get IT done!</h1>
                </div>
                <img
                    className={`${about_us_header_image} col-7`}
                    src="../../images/about-us-header.jpg"
                    alt="about-us-header"
                />
            </div>
        </div>
    );
};

export default AboutUsHeader;
