import * as React from 'react';
import { Helmet } from 'react-helmet';
import AboutUsHeader from '../compontents/AboutUsHeader/AboutUsHeader';
import Companies from '../compontents/Companies/Companies';
import ContactUsButton from '../compontents/ContactUsButton/ContactUsButton';
import Layout from '../compontents/Layout/Layout';
import LinkText1 from '../compontents/LinkText1/LinkText1';
import LinkText2 from '../compontents/LinkText2/LinkText2';

type AboutUsPageProps = {
    location: any;
};

const AboutUsPage: React.FC<AboutUsPageProps> = ({ location }) => {
    return (
        <Layout location={location}>
            <Helmet>
                <title>About Us - Meet the Team!</title>
            </Helmet>
            <div className="page-content">
                <div className="row">
                    <AboutUsHeader></AboutUsHeader>
                </div>
                <LinkText1></LinkText1>
                <LinkText2></LinkText2>
                <Companies></Companies>
            </div>
            <div className="get_in_touch_section" data-testid="get-in-touch-section">
                <div className="get_in_touch_text p-2" data-testid="get-in-touch-text">
                    Contact us now to get your personalized solutions to your problems.
                </div>
                <div className="mt-sm-4" data-testid="get-in-touch-link">
                    <ContactUsButton />
                </div>
            </div>
        </Layout>
    );
};

export default AboutUsPage;
